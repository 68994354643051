import {
  FetchEventSourceInit,
  fetchEventSource,
  EventSourceMessage,
} from "@microsoft/fetch-event-source";

export interface CustomConfig extends FetchEventSourceInit {
  onerror?: (err: any, errInfo?: any) => number | void | null | undefined;
  onRetry?: () => void;
  onclose?: (type?: string) => void;
  onmessage: (e: EventSourceMessage, data?: any) => void;
  timeout?: number;
  tryTimes?: number;
}

// sse结束标识
const EXP_END_SIGN = /\uD83D\uDC4D/;

export const eventSource = (input: RequestInfo, config: CustomConfig): void => {
  const {
    signal,
    onRetry,
    timeout = 10000,
    tryTimes = 3,
    onmessage: _onmessage,
    onclose: _onclose,
    onerror: _onerror,
    ...sourceConfig
  } = config;

  console.log("超时timeout", timeout);

  let startDate = Date.now();
  let timerId: number | null;
  let requestTimes = 0;
  // let msgStr = ''
  let abortController: AbortController | null = new AbortController();
  let isEnded = false;

  // 取消请求
  const cancel = () => {
    timerId && clearInterval(timerId);
    timerId = null;
    abortController?.abort();
    abortController = null;
  };

  signal?.addEventListener("abort", () => {
    cancel();
    _onclose?.("abort");
  });

  // 超时重连检测
  const startCheckTimeout = () => {
    timerId && clearInterval(timerId);
    timerId = setTimeout(() => {
      if (Date.now() - startDate > timeout) {
        cancel();
        onerror("请求超时");
      } else {
        startCheckTimeout();
      }
    }, 200) as unknown as number;
  };

  // 重连
  const retry = () => {
    requestTimes += 1;
    abortController && abortController.abort();
    abortController = new AbortController();
    startDate = Date.now();
    onRetry?.();
    request();
  };

  // 接受消息
  const onmessage = (ev: EventSourceMessage) => {
    // 异常
    if (/lbError:/.test(ev.data)) {
      try {
        const data = JSON.parse(ev.data.replace("lbError:", ""));
        onerror(data.msg, data, true);
        return;
      } catch (err) {
        onerror("", {}, true);
        return;
      }
    }
    // console.log(ev);

    // msgStr += ev.data
    let str = ev.data.replace(EXP_END_SIGN, "");

    str = str.replace(/<laiBo\/>/g, "\n ");
    str = str.replace(/<laiBoSpace\/>/g, " ");
    startDate = Date.now();
    startCheckTimeout();

    if (str === "[DONE]") {
      cancel();
      onclose();
      return;
    }

    let messageId = "";
    let params;
    let newEv = { ...ev, data: str, id: ev.id || messageId };
    try {
      params = JSON.parse(str);

      if (params.choices) {
        str = params.choices
          .map((item: any) => item.delta?.content || "")
          .join("");
        messageId = params.id;
      } else if (params.code === -1 || params.code === -10020) {
        onerror(params.msg, params);
      }

      let event = ev.event;
      if (
        params.choices &&
        params.choices[0] &&
        params.choices[0].finish_reason == "length"
      ) {
        event = "continue";
      } else if (
        params.choices &&
        params.choices[0] &&
        params.choices[0].finish_reason == "stop"
      ) {
        event = "stop";
        cancel();
        onclose();
      }
      newEv = { ...ev, event, data: str, id: ev.id || messageId };
    } catch (err) {}
    _onmessage?.(newEv, params);
  };

  // 会话结束
  const onclose = () => {
    timerId && clearInterval(timerId);
    timerId = null;
    abortController = null;
    // 走msg消息结束的请求可能存在error之后触发close
    if (!isEnded) {
      _onclose?.();
    }
  };

  // 请求异常
  const onerror = (errMsg: string, errInfo?: any, stopRequest?: boolean) => {
    timerId && clearInterval(timerId);
    timerId = null;
    abortController = null;

    if (!stopRequest && tryTimes && requestTimes < tryTimes) {
      retry();
    } else {
      isEnded = true;
      const defaultErrorMsg = "哎呀，一下卡住了，不知道说什么了^_^";
      //  errMsg 不存在 或则是 fetchEventSource抛出的内部英文错误 使用默认错误提示 否则使用解析出来的错误提示
      let errorMsg =
        (errMsg &&
          typeof errMsg === "string" &&
          errMsg.startsWith("Expected content-type to be")) ||
        !errMsg
          ? defaultErrorMsg
          : errMsg;
      _onerror?.(errorMsg, errInfo);
      cancel();
      onclose();
      throw Error("");
    }
  };

  // 发起请求
  const request = () => {
    fetchEventSource(input, {
      ...sourceConfig,
      onmessage,
      onclose,
      onerror,
      signal: abortController?.signal,
      openWhenHidden: true,
    });
    startCheckTimeout();
  };

  request();
};
